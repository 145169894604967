/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import RuleIcon from '@mui/icons-material/Rule';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import {
  Box, IconButton, Dialog, DialogContent, DialogActions, Button, Tooltip, Typography, Chip
} from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import Vote from './Vote';
import Confirmation from './Confirmation';
import { COLORS } from '../constants';
import { useSocket } from '../utils/socketContext';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import { selectUserEmail } from '../redux/Slices/userSlice';
import { resetError, selectError } from '../redux/Slices/usageSlice';
import { selectAllClassLabels, selectAllIndivLabels, selectAllObjectLabels } from '../redux/Slices/searchBarSlice';

/**
 ** Utils : Custom styles for the table
*/

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.lightBlueConcerto,
    color: COLORS.primary,
    fontSize: 18,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  textAlign: 'center',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const typographyStyle = {
  m: 0.5,
  fontWeight: 'bold',
  color: COLORS.gray2,
  textDecoration: 'underline',
  WebkitBackgroundClip: 'text',
};

export const getChipColor = (label, allClassLabels, allIndivLabels, allObjectLabels) => {
  if (allIndivLabels.has(label)) return COLORS.lightBlue;
  if (allClassLabels.has(label)) return COLORS.greenLightConcerto;
  if (allObjectLabels.has(label)) return COLORS.orangeConcerto;
  return COLORS.gray2;
};


/**
 ** Component for the Usages table
*/

function Usages({ usagesPerEntityLabel }) {
  const socket = useSocket();
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectUserEmail);
  const allIndivLabels = useAppSelector(selectAllIndivLabels);
  const allClassLabels = useAppSelector(selectAllClassLabels);
  const allObjectLabels = useAppSelector(selectAllObjectLabels);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const error = useAppSelector(selectError);
  const [isToggled, setIsToggled] = useState(false); // Visbility of the table
  const [openDialog, setOpenDialog] = useState(false); // Visibility of the dialog vote form
  const [selectedUsage, setSelectedUsage] = useState(null);
  const [usageDetails, setSelectedUsageDetails] = useState({});

  const handleVoteOpen = (usage) => () => {
    setSelectedUsage(usage);
    setSelectedUsageDetails({
      usageID: usage.id,
      entityType: usage.entities.type,
      entityLabel: usage.entities.label,
      subject: usage.subject,
      predicate: usage.predicate,
      object: usage.object,
      validator: email,
    });
    setOpenDialog(true);
  };

  const toggleVisibility = () => {
    setIsToggled((prevState) => !prevState);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    if (!socket || socket === null) return;
    if (socket && !socket.connected) {
      socket.connect();
    }

    socket?.on('UsageValidated', (data) => {
      if (data.usageID === usageDetails.usageID) {
        if (data.valInfo.email !== email) {
          setOpenDialog(false);
          setShowSnackbar(true);
        }
      }
    });
    // return () => {
    //   dispatch(resetError());
    // }
  }, [socket]);

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: 7, padding: 2,
      }}
      >
        <Box sx={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%',
        }}
        >
          <Chip
            label={usagesPerEntityLabel[0]?.entities?.label}
            sx={{backgroundColor: usagesPerEntityLabel[0]?.entities?.type === 'Individual' ? COLORS.lightBlue : usagesPerEntityLabel[0]?.entities?.type === 'Class' ? COLORS.greenLightConcerto : COLORS.orangeConcerto, fontWeight: 'bold'}}
          />
          <Typography sx={{ fontSize: '0.6rem', fontWeight: 'italic', mr: 5 }}>
            Total Usages:
            {' '}
            {usagesPerEntityLabel?.length}
          </Typography>

        </Box>
        {isToggled ? (
          <KeyboardArrowUpIcon onClick={toggleVisibility} />
        ) : (
          <KeyboardArrowDownIcon onClick={toggleVisibility} />
        )}
      </Box>
      {isToggled && (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, padding: 5 }} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Subject</StyledTableCell>
              <StyledTableCell align="right">Predicate</StyledTableCell>
              <StyledTableCell align="right">Object</StyledTableCell>
              <StyledTableCell align="center">Vote</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {usagesPerEntityLabel?.map((us, index) => 
              {
                const isColored = allIndivLabels.has(us.subject) || allIndivLabels.has(us.predicate) || allIndivLabels.has(us.object);    
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 },
                  }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <Chip label={us.subject} sx={{backgroundColor: getChipColor(us.subject, allClassLabels, allIndivLabels, allObjectLabels), fontWeight: 'bold'}} />
                    </StyledTableCell>
                    <StyledTableCell align="right" >
                      <Chip
                      label={us.predicate}
                      sx={{ backgroundColor: getChipColor(us.predicate, allClassLabels, allIndivLabels, allObjectLabels), fontWeight: 'bold'}}
                      />
                      </StyledTableCell>
                    <StyledTableCell align="right" >
                      <Chip
                      label={us.object}
                      sx={{backgroundColor: getChipColor(us.object, allClassLabels, allIndivLabels, allObjectLabels), fontWeight: 'bold'}}
                      />
                  </StyledTableCell>
                    {!isColored && us.entities?.type !== 'Individual' ? (
                      <StyledTableCell align="center">
                        <Tooltip title="Click to vote">
                          <IconButton onClick={handleVoteOpen(us)}>
                            <RuleIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    ) : 
                    <StyledTableCell align="center">
                      <Tooltip title="No vote possible for Individuals">
                          <HorizontalRuleIcon />
                      </Tooltip>
                  </StyledTableCell>}
                  </StyledTableRow>

                )

              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      )}

      {/* Dialog Component for the Vote Form */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '60%',
            maxWidth: '30vw',
          },
        }}
      >
        <DialogContent>
          <Vote setOpenDialog={setOpenDialog} usageDetails={usageDetails} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Confirmation
        open={showSnackbar}
        handleClose={handleCloseSnackbar}
        message="Usage already validated by another user"
        severity="error"
      />
      {(error !== null && error !== undefined && error!== '') && <Typography sx={{ color: 'red', fontWeight: 'bold' }}>Please Reload the page</Typography>}
    </Box>
  );
}

export default Usages;
