import React, { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks/hooks';
import UnderConstruction from '../components/UnderConstruction';
import { resetSearchStore } from '../redux/Slices/searchBarSlice';

function Queries() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetSearchStore());
  }, []);
  return (
    <UnderConstruction name="Queries" />
  );
}

export default Queries;
