/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';
import TextField from '@mui/material/TextField';

const MemoizedTextField = React.memo(({
  label, value, onChange, error, helperText,
}) => (
  <TextField
    label={label}
    variant="outlined"
    fullWidth
    multiline
    rows={4}
    value={value}
    onChange={onChange}
    error={error}
    helperText={helperText}
  />
));

export default MemoizedTextField;
