/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../config/axios/axios.config';

const initialState = {
  email: '',
  name: '',
  isAuthenticated: false,
  orgaId: '',
  organisationName: '',
  error: null,
  validatedUsages: [],
};

export const checkAuthStatus = createAsyncThunk(
  'auth/checkStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('auth/validate');
      return response.data.isAuthenticated;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const validatedUsages = createAsyncThunk(
  'user/ValidatedUsages',
  async (email, { rejectWithValue }) => {
    try {
      const response = await api.post('/axioms/validated', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      /** * Guessing the name from the email */
      const email = action.payload;
      const namePart = email.split('@')[0];
      const [match] = namePart.match(/^[A-Za-z]+/);
      if (match) state.name = match;
      else state.name = namePart;

      /** Registering the email */
      state.email = email;
    },
    setIsAuth: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setOrgaId: (state, action) => {
      state.orgaId = action.payload;
    },
    setOrgaName: (state, action) => {
      state.organisationName = action.payload;
    },
    resetAuthStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line no-unused-vars
      .addCase(checkAuthStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(validatedUsages.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(validatedUsages.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.validations?.length > 0) {
          state.validatedUsages = action.payload.validations;
        }
      })
      .addCase(validatedUsages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setEmail,
  setIsAuth,
  setOrgaId,
  setOrgaName,
  resetAuthStore,
} = userSlice.actions;

export const selectUserEmail = (state) => state.persistedReducer.user.email;
export const selectUserName = (state) => state.persistedReducer.user.name;
export const selectIsAuth = (state) => state.persistedReducer.user.isAuthenticated;
export const selectOrgaId = (state) => state.persistedReducer.user.orgaId;
export const selectValidatedUsages = (state) => state.persistedReducer.user.validatedUsages;
export const selectorgaName = (state) => state.persistedReducer.user.organisationName;

export default userSlice.reducer;
