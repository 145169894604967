import React, { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks/hooks';
import { resetSearchStore } from '../redux/Slices/searchBarSlice';
import UnderConstruction from '../components/UnderConstruction';

function Assumptions() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetSearchStore());
  }, []);
  return (
    <UnderConstruction name="Assumptions" />
  );
}

export default Assumptions;
