/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  TextField, Button, Container, Typography, Box,
} from '@mui/material';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo/logo.png';

import { COLORS } from '../constants';
import {
  setEmail, setIsAuth, setOrgaId, setOrgaName,
} from '../redux/Slices/userSlice';
import { useAppDispatch } from '../redux/hooks/hooks';
import { login } from '../api/auth/auth.service';

function Authentication() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /** States */
  const [email, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  /** Utils: functions */

  const handleChange = (e) => {
    if (error && e.target.value.trim() !== '') {
      setError(false);
    }
    const { name, value } = e.target;
    if (name === 'email') {
      setUserEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hashedPwd = CryptoJS.SHA256(password).toString();
    try {
      const user = await login({ email, password: hashedPwd });
      dispatch(setOrgaId(user.data.orgaID));
      dispatch(setEmail(email));
      dispatch(setIsAuth(true));
      dispatch(setOrgaName(user.data.organizations.name));
      if (!user.data.firstCo) {
        navigate('/home');
      } else {
        navigate('/updatepwd');
      }
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '50vh', alignItems: 'center', mt: '20vh',
      }}
    >
      <img src={logo} alt="Concerto Logo" style={{ maxHeight: '100px' }} />
      <Typography variant="h4" component="h1">Log in</Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Email"
          name="email"
          value={email}
          onChange={handleChange}
          margin="normal"
          error={error}
          fullWidth
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
          margin="normal"
          fullWidth
          error={error} // Use the error state to control the error display
          helperText={error ? 'Invalid credentials' : ''}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ '&:hover': { backgroundColor: COLORS.greenConcerto }, mt: 3 }}>
          Log in
        </Button>
      </Box>
    </Container>
  );
}

export default Authentication;
