import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Home from './pages/Home';
import Axioms from './pages/Axioms';
import Account from './pages/Account';
import Queries from './pages/Queries';
import Assumptions from './pages/Assumptions';
import Authentication from './pages/Authentication';
import { useAppDispatch } from './redux/hooks/hooks';
import ProtectedRoute from './components/ProtectedRoute';
import { checkAuthStatus } from './redux/Slices/userSlice';
// import Dashboard from './pages/Dashboard';
import DashboardBis from './pages/DashboardBis';
import UpdatePwd from './pages/Updatepwd';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/axioms" element={<ProtectedRoute element={<Axioms />} />} />
        <Route path="/queries" element={<ProtectedRoute element={<Queries />} />} />
        <Route path="/account" element={<ProtectedRoute element={<Account />} />} />
        <Route path="/" element={<Authentication />} />
        <Route path="/assumptions" element={<ProtectedRoute element={<Assumptions />} />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<DashboardBis />} />} />
        <Route path="/updatepwd" element={<ProtectedRoute element={<UpdatePwd />} />} />

      </Routes>
    </Router>
  );
}

export default App;
