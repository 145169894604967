/* eslint-disable no-console */
import React from 'react';
import {
  Container, Typography, Box,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import EntryDashboard from '../components/submenus/Account/EntryDashboard';
import { useAppSelector } from '../redux/hooks/hooks';
import { selectorgaName } from '../redux/Slices/userSlice';
// import { COLORS } from '../constants';

const entities = ['Classes', 'Individuals', 'Relationships'];
const entitiesInDb = ['Class', 'Individual', 'Object Property'];

function DashboardBis() {
  const org = useAppSelector(selectorgaName);

  return (
    <Container sx={{ height: '80vh', justifyContent: 'space-around', width: '100vw' }}>
      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
      }}
      >
        <Typography
          sx={{
            mb: 5, fontWeight: 'bold', fontSize: '20px', mr: 2,
          }}
          component="div"
        >
          Treated usages overview
        </Typography>
        <Chip label={org} />
      </Box>
      {entities.map((entity, index) => (
        <EntryDashboard
          key={entities.indexOf(entity)}
          entityName={entity}
          attributeName={entitiesInDb[index]}
        />
      ))}
    </Container>

  );
}

export default DashboardBis;
