/* eslint-disable no-console */
/* eslint-disable react/prop-types */
// src/socketContext.js
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialize socket connection
    const newSocket = io('/', { autoConnect: false, transports: ['websocket'], withCredentials: true });
    setSocket(newSocket);

    return () => {
      newSocket.close();
    }; // Cleanup on unmount
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
}
