/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { COLORS } from '../../../constants';
import { formatDate } from '../../../utils/usage';
import { useAppSelector } from '../../../redux/hooks/hooks';
import { getChipColor } from '../../Usages';
import { selectAllClassLabels, selectAllIndivLabels, selectAllObjectLabels } from '../../../redux/Slices/searchBarSlice';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: COLORS.lightWhite,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: COLORS.darkBlueConcerto,
  borderRadius: '6px',
  elevation: 10,
  borderShadow: '2px',
  fontSize: '0.7rem',
  width: '30vw',
}));


function CardCounts({
  vote, comment, validation_date, subject, predicate, object,
}) {
  const allClassLabels = useAppSelector(selectAllClassLabels);
  const allIndivLabels = useAppSelector(selectAllIndivLabels);
  const allObjectLabels = useAppSelector(selectAllObjectLabels);

  const badgeIcon = vote === 'OK' ? (
    <CheckCircleIcon style={{ color: COLORS.greenConcerto }} />
  ) : (
    <CancelIcon style={{ color: 'red' }} />
  );

  const tooltipTitle = (
    <Paper>
      <Typography component="div" variant="body2">
        {vote === 'OK' && (
          <Box sx={{ m: 3 }}>
            <strong style={{ fontWeight: 'italic' }}>Validation Date:</strong>
            {' '}
            {formatDate(validation_date)}
          </Box>
        )}
        {vote === 'NOK' && (
          <Box sx={{ m: 3 }}>
            <div style={{ marginBottom: 3, fontWeight: 'italic' }}>
              <strong>Validation Date:</strong>
              {' '}
              {formatDate(validation_date)}
            </div>
            <div>
              <strong>Comment:</strong>
              {' '}
              {comment || 'No comment'}
            </div>
          </Box>
        )}
      </Typography>
    </Paper>
  );



  return (

    <Tooltip title={tooltipTitle} placement="right">
      <Badge badgeContent={badgeIcon} color="default" style={{ fontSize: 'xl' }}>
        <Box>
          <Item>
            <Chip label={subject} sx={{backgroundColor: getChipColor(subject, allClassLabels, allIndivLabels, allObjectLabels),fontWeight: 'bold', m: 1}}/>
            ; 
            <Chip label={predicate} sx={{backgroundColor: getChipColor(predicate, allClassLabels, allIndivLabels, allObjectLabels), fontWeight: 'bold', m: 1}}/>
            ; 
            <Chip label={object} sx={{backgroundColor: getChipColor(object, allClassLabels, allIndivLabels, allObjectLabels), fontWeight: 'bold', m: 1}}/>
          </Item>
        </Box>
      </Badge>
    </Tooltip>
  );
}

export default CardCounts;
