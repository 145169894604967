/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks/hooks';
import { selectIsAuth } from '../redux/Slices/userSlice';

function ProtectedRoute({ element }) {
  const isAuthenticated = useAppSelector(selectIsAuth);
  return isAuthenticated ? element : <Navigate to="/" />;
}
export default ProtectedRoute;
