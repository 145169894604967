import React from 'react';
import { Typography, Box, Chip } from '@mui/material';
import { COLORS } from '../constants';

const Legend = ({ items }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyItems: 'space-around',
      p: 1,
      border: '1px dashed',
      borderColor: 'divider',
      borderRadius: 1,
      position: 'fixed',
      mt: 20, 
      mr: 1, 
      ml: 2,
      width: 'auto',
      right: { xs: '10px', sm: '20px', md: '30px' }, // Adjust position based on screen size
      bottom: '20px',
      zIndex: 1000,
      backgroundColor: 'white', // Ensure readability
      '& > *': {
        mr: { xs: 0.5, sm: 1, md: 2 }, // Adjust spacing between chips based on screen size
      },
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: 'italic', fontSize: "1rem", textDecoration: 'underline', color: COLORS.gray }}>Legend:</Typography>
    {items.map((item, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1, ml: 2 }}>
        <Chip label={item.label} sx={{ backgroundColor: item.color, fontWeight: 'bold' }} />
      </Box>
    ))}
  </Box>
);

export default Legend;
