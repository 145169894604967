/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  TextField, Button, Container, Typography, Box,
} from '@mui/material';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../../constants';
import Confirmation from '../../Confirmation';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/hooks';
import { selectUserEmail, resetAuthStore } from '../../../redux/Slices/userSlice';
import { resetSearchStore } from '../../../redux/Slices/searchBarSlice';
import { logout, updatePwd } from '../../../api/auth/auth.service';

function Security() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectUserEmail);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;

    if (password === '') {
      setPasswordError(true);
      return;
    }
    const hashedPwd = CryptoJS.SHA256(password).toString();
    try {
      await updatePwd({ email, password: hashedPwd });
      setShowSnackbar(true);
      dispatch(resetAuthStore());
      dispatch(resetSearchStore());
      await logout();
      navigate('/');
    } catch (err) {
      setPasswordError(true);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleChange = (e) => {
    if (passwordError && e.target.value.trim() !== '') {
      setPasswordError(false); // Clears the error message as user starts typing
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '50vh', alignItems: 'center',
      }}
    >
      <Typography variant="h4" component="h1">Update your password</Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Password"
          name="password"
          type="password"
          // value={credentials.password}
          onChange={handleChange}
          margin="normal"
          fullWidth
          error={passwordError} // Use the error state to control the error display
          helperText={passwordError ? 'Password cannot be empty' : ''} // Display error message if passwordError is true
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ '&:hover': { backgroundColor: COLORS.greenConcerto }, mt: 3 }}>
          Submit
        </Button>
      </Box>
      <Confirmation
        open={showSnackbar}
        handleClose={handleCloseSnackbar}
        message="Your password has been changed successfully!"
        severity="success"
      />
    </Container>
  );
}

export default Security;
