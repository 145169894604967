/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getEntitiesByOrgaId } from '../../api/axioms/axioms.service';
import { getEveryEntities } from '../../api/axioms/axioms.service';

const initialState = {
  searchInput: undefined,
  loading: false,
  allIndivLabels: new Set(),
  allClassLabels: new Set(),
  allObjectLabels: new Set(),
  searchList: [],
};

export const getEntities = createAsyncThunk(
  'entities/byOrgaId',
  async (orgaId, { rejectWithValue }) => {
    try {
      const response = await getEntitiesByOrgaId(orgaId, 1);
      const filteredOptions = (response.data).reduce((acc, current) => {
        const x = acc.find((item) => item.label === current.label);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);
      return filteredOptions;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllEntities = createAsyncThunk(
  'entities/all',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getEveryEntities();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
    setSearchList: (state, action) => {
      state.searchList = action.payload;
    },
    resetSearchInput: (state) => {
      state.searchInput = undefined;
    },
    resetSearchStore: (state) => {
      state.searchInput = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line no-unused-vars
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.searchList = action.payload;
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllEntities.pending, (state, action) => {
        state.loading = true;
      })
      // eslint-disable-next-line no-unused-vars
      .addCase(getAllEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.allIndivLabels = new Set(action.payload.filter((item) => item.type === 'Individual').map((item) => item.label));
        state.allClassLabels = new Set(action.payload.filter((item) => item.type === 'Class').map((item) => item.label));
        state.allObjectLabels = new Set(action.payload.filter((item) => item.type === 'Object Property').map((item) => item.label));
      })
      .addCase(getAllEntities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setSearchInput,
  setSearchList,
  resetSearchInput,
  resetSearchStore,
} = searchSlice.actions;

export const selectSearchList = (state) => state.persistedReducer.search.searchList;
export const selectSearchInput = (state) => state.persistedReducer.search.searchInput;
export const selectAllIndivLabels = (state) => state.persistedReducer.search.allIndivLabels;
export const selectAllClassLabels = (state) => state.persistedReducer.search.allClassLabels;
export const selectAllObjectLabels = (state) => state.persistedReducer.search.allObjectLabels;

export default searchSlice.reducer;
