import React from 'react';
import { Box, Typography } from '@mui/material';
import Security from '../components/submenus/Account/Security';
import Navbar from '../components/Navbar';
import { COLORS } from '../constants';

function UpdatePwd() {
  return (
    <>
      <Navbar />
      <Box sx={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '60%',
      }}
      >
        <Typography component="div" sx={{ m: 4, fontWeight: 'bold', color: COLORS.greenConcerto }}>
          *It&apos;s your first time here, please update
          your password and
          {' '}
          <span style={{ textDecoration: 'underline' }}> reconnect </span>
          for security concerns
          {' '}
        </Typography>
        <Security />
      </Box>
    </>
  );
}

export default UpdatePwd;
