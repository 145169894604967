/* eslint-disable no-console */
import axios from 'axios';

const api = axios.create({
  withCredentials: true,
  baseURL:'/api/v1',
});

api.interceptors.request.use((config) => config);

api.interceptors.response.use(
  (response) => response,
  /* If the request is OK, return the response */
  async (error) => Promise.reject(error)
  /* If the request is KO:
        Retrieving the intial config of the request */
  // const { config } = error;
  ,
);

export default api;
