/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/hooks';
import UsageApproval from './UsageApproval';
import { COLORS } from '../../../constants';
import { selectValidatedUsages, selectorgaName } from '../../../redux/Slices/userSlice';
import { getAllEntities } from '../../../redux/Slices/searchBarSlice';

/**
 * Utils: Styling for the Entry component
 */
const typographyStyle = {
  fontSize: '1.6rem',
  fontWeight: 'bold',
  color: COLORS.greenConcerto,
  opacity: 0.65,
  display: 'inline',
};

const containerBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  mb: 1,
};

/**
 * Component for the Entry
*/

function EntryDashboard({ entityName, attributeName }) {
  const validatedUsages = useAppSelector(selectValidatedUsages);
  const org = useAppSelector(selectorgaName);
  const dispatch = useAppDispatch();
  const [isToggled, setIsToggled] = useState(false);
  const [usagePerEntity, setUsagePerEntity] = useState([]);

  const toggleVisibility = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  useEffect(() => {
    /**
       * * Aggregating usages per entity per label */
    if (validatedUsages) {
      const validatedUsagesByLabel = validatedUsages.reduce((acc, usage) => {
        if (usage.usages.entities.type === attributeName) {
          const { label } = usage.usages.entities;
          if (!acc[label]) {
            acc[label] = [];
          }
          acc[label].push(usage);
        }
        return acc;
      }, {});
      setUsagePerEntity(Object.values(validatedUsagesByLabel));
    }
    /**
       * * ********** */
      dispatch(getAllEntities())
  }, [validatedUsages, entityName]);

  return (
    <Box sx={{
      width: '95%', height: '25%', overflow: 'auto', ml: 5,
    }}
    >
      { entityName !== 'Individuals' ? (
        <>
          <Box sx={containerBoxStyle}>
            <Typography component="div" sx={typographyStyle}>{entityName}</Typography>
            {isToggled ? (
              <KeyboardArrowUpIcon onClick={toggleVisibility} />
            ) : (
              <KeyboardArrowDownIcon onClick={toggleVisibility} />
            )}
          </Box>
          <Divider variant="middle" sx={{ backgroundColor: '#eee', marginY: 2.5, borderBottomWidth: 1.75 }} />
        </>
      ) : null }
      {isToggled && (
      <Box sx={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
      }}
      >
        {usagePerEntity.length > 0 ? (
          usagePerEntity.map((validation, index) => (
            <Box key={index} sx={{ m: 2 }}>
              <Typography component="div" sx={{ fontWeight: 'bold', fontSize: '0.7rem', mb: '0.7' }}>{validation[0].usages.entities.label}</Typography>
              <UsageApproval validation={validation} />
            </Box>
          ))) : (
            <Box>
              <Typography component="div" sx={{ mb: 2 }}>
                No usages treated yet
              </Typography>
            </Box>
        )}
      </Box>
      )}
    </Box>
  );
}

export default EntryDashboard;
