import { persistStore, persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { rootPersistConfig, rootReducer } from './rootReducer';

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer:
    {
      persistedReducer,
    },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
  devTools: true,
});

const persistor = persistStore(store);
/**
 * ! Used to purge the state when user quits page or refreshes which is problematic
 */
// persistor.purge();

export default persistor;
