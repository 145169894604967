import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import userReducer from './Slices/userSlice';
import usageReducer from './Slices/usageSlice';
import searchReducer from './Slices/searchBarSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  usage: usageReducer,
  user: userReducer,
  search: searchReducer,
});

export { rootPersistConfig, rootReducer };
