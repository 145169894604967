/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useAppDispatch } from '../redux/hooks/hooks';
import UnderConstruction from '../components/UnderConstruction';
import { resetSearchStore } from '../redux/Slices/searchBarSlice';

function Home() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetSearchStore());
  }, []);

  return (
    <UnderConstruction name="Home" />
  );
}

export default Home;
