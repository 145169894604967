import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import TabMenu from '../components/submenus/TabMenu';
import { resetSearchStore } from '../redux/Slices/searchBarSlice';
import { useAppDispatch } from '../redux/hooks/hooks';

function Account() {
  const dispatch = useAppDispatch();
  const menu = {
    labelOne: 'My informations',
    labelTwo: 'My security',
    labelThree: 'My dashboard',
  };

  useEffect(() => {
    dispatch(resetSearchStore());
  }, []);

  return (
    <>
      <Navbar />
      <TabMenu data={menu} />
    </>
  );
}

export default Account;
