const transformUsage = (usage) => {
  const parts = usage.substring(1, usage.length - 1).split(', ');
  const values = parts.map((part) => part.replace(/'/g, ''));
  return values;
};

function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.toLocaleDateString('en-US', {
    weekday: 'short', year: 'numeric', month: 'long', day: 'numeric',
  })} at ${date.toLocaleTimeString('en-FR', { hour: '2-digit', minute: '2-digit' })}`;
}

export { transformUsage, formatDate };
