/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAxioms, validateUsage } from '../../api/axioms/axioms.service';

const initialState = {
  allUsages: [],
};

export const getAllUsages = createAsyncThunk(
  'usages/getAllUsages',
  async ({ orgaId, userEmail }, { rejectWithValue }) => {
    try {
      const response = await getAxioms({ orgaId, userEmail });
      // eslint-disable-next-line no-console
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const valUsage = createAsyncThunk(
  'usages/validation',
  async (valInfo, { rejectWithValue }) => {
    try {
      const response = await validateUsage(valInfo);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);


/**
 * !Handle loading state in proper component
 */

const usageSlice = createSlice({
  name: 'usage',
  initialState,
  reducers: {
    setUsage: (state, action) => {
      state.usage = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    resetUsageStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line no-unused-vars
      .addCase(getAllUsages.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllUsages.fulfilled, (state, action) => {
        state.loading = false;
        state.allUsages = action.payload;
      })
      .addCase(getAllUsages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(valUsage.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(valUsage.fulfilled, (state, action) => {
        
        state.loading = false;
        state.allUsages = state.allUsages.filter((usage) => usage.id !== action.payload.usageID);
      })
      .addCase(valUsage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setUsage,
  resetLoading,
  resetError,
} = usageSlice.actions;

export const selectAllUsages = (state) => state.persistedReducer.usage.allUsages;
export const selectError = (state) => state.persistedReducer.usage.error;
export const selectLoading = (state) => state.persistedReducer.usage.loading;

export default usageSlice.reducer;
