/* eslint-disable no-console */
import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { logout } from '../api/auth/auth.service';
import Confirmation from './Confirmation';
import { resetAuthStore, selectUserName } from '../redux/Slices/userSlice';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';

import { COLORS } from '../constants';
import logo from '../assets/logo/logo.png';
import { resetSearchStore } from '../redux/Slices/searchBarSlice';

const pages = ['Home', 'Axioms', 'Assumptions', 'Queries'];
const settings = ['Account', 'Logout'];

function Navbar() {
  const name = useAppSelector(selectUserName);
  const dispatch = useAppDispatch();

  /**
 ** State for the anchor element of the menu
 */
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [error, setErr] = React.useState('');
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /**
   * Logout function
   */

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(resetAuthStore());
      dispatch(resetSearchStore());
      navigate('/');
    } catch (err) {
      setShowSnackbar(true);
      setErr(err);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  /**
 ** Utils: Common Navigation links for small and large screens
 */
  const renderLink = (page, asMenuItem = false) => {
    const toPath = `/${page.toLowerCase()}`;

    return asMenuItem ? (
      <MenuItem key={page} onClick={handleCloseNavMenu} component={RouterLink} to={toPath}>
        <Typography textAlign="center" sx={{ color: COLORS.black, fontWeight: 'bold', '&:hover': { color: COLORS.greenConcerto, backgroundColor: 'transparent' } }}>{page}</Typography>
      </MenuItem>
    ) : (
      <Button
        key={page}
        component={RouterLink}
        to={toPath}
        onClick={handleCloseNavMenu}
        sx={{
          my: 2,
          color: COLORS.black,
          display: 'block',
          fontWeight: 'bold',
          '&:hover': { color: COLORS.greenConcerto, backgroundColor: 'transparent' },
        }}
      >
        {page}
      </Button>
    );
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: COLORS.lightWhite, opacity: 0.75 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

            <img
              src={logo}
              alt="Concerto Logo"
              style={{
                height: '5vh', display: 'flex', mb: 3,
              }}
            />
            {/* Menu burger for small screens  */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color={COLORS.black}
                sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                data-testid="navMenu"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  renderLink(page, true)
                ))}
              </Menu>
            </Box>

            {/* For medium to large screens  */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', mr: 'auto' }}>
              {pages.map((page) => (
                renderLink(page)
              ))}
            </Box>

            {/* User info & Logout */}
            <Box sx={{
              display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ml: 'auto',
            }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="X" src="" />
                </IconButton>
              </Tooltip>
              <Typography
                data-testid="username"
                variant="h6"
                component="div"
                sx={{
                  color: COLORS.black, mr: 1, fontSize: '12px', ml: 1, fontWeight: 'bold',
                }}
              >
                {name.toUpperCase()}
              </Typography>
              <Tooltip title="Logout">
                <IconButton onClick={handleLogout}>
                  <ExitToAppIcon sx={{ color: COLORS.black, ml: 3 }} />
                </IconButton>
              </Tooltip>

              {/* Submenu for User settings  */}
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={() => { navigate(`/${setting.toLowerCase()}`); }}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Confirmation
        open={showSnackbar}
        handleClose={handleCloseSnackbar}
        message={error.message ? error.message : 'Logout failed. Please try again.'}
        severity="error"
      />
    </>

  );
}

export default Navbar;
