/* eslint-disable no-console */
import api from '../../config/axios/axios.config';

const getAxioms = ({ orgaId, userEmail }) => api.post('/axioms/usages', { orgaId, userEmail });

const getEntitiesByOrgaId = (orgaId, page) => api.post(`/entities/byOrgaId?page=${page}&limit=`, { orgaId });

const getEveryEntities = () => api.get('/entities/allEntities');

const validateUsage = (valInfo) => api.post('/axioms/validation', valInfo);

const getValidatedUsagesByUser = (email) => api.post('/axioms/validated', { email });

export {
  getAxioms, getEntitiesByOrgaId, validateUsage
  , getValidatedUsagesByUser, getEveryEntities
};
