/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { Box } from '@mui/material';
import * as React from 'react';
import CardCounts from './Cards';
import { legendItems } from '../../../pages/Axioms';
import Legend from '../../Legend';

function UsageApproval({ validation }) {
  return (
    <>
      {validation.map((val, index) => {
        const {
          vote, comment, validation_date, usages,
        } = val;
        const { label, type } = usages.entities; // Further destructuring for nested objects
        const { subject, predicate, object } = usages;

        return (
          <Box key={index} sx={{ m: 0.7 }}>
            <Legend items={legendItems} />
            <CardCounts
              key={usages.id}
              vote={vote}
              comment={comment}
              validation_date={validation_date}
              subject={subject}
              predicate={predicate}
              object={object}
            />
          </Box>
        );
      })}
    </>

  );
}

export default UsageApproval;
