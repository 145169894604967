/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Usages from './Usages';

import { COLORS } from '../constants';
import { selectAllUsages } from '../redux/Slices/usageSlice';
import { useAppSelector } from '../redux/hooks/hooks';
import { selectSearchInput } from '../redux/Slices/searchBarSlice';

/**
 * Utils: Styling for the Entry component
 */
const typographyStyle = {
  m: 2,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  opacity: 0.75,
  display: 'inline',
};

const containerBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '',
  borderRadius: 8,
  mb: 1,
};

const getColorByEntity = (entityName) => {
  switch (entityName) {
    case 'Classes':
      return COLORS.greenLightConcerto;
    case 'Individuals':
      return COLORS.lightBlue;
    case 'Relationships':
        return COLORS.orangeConcerto;
    default:
      return 'black'; 
  }
}

/**
 * Component for the Entry
*/

function Entry({ entityName, attributeName }) {
  const usages = useAppSelector(selectAllUsages);
  const [isToggled, setIsToggled] = useState(false);
  const searchInput = useAppSelector(selectSearchInput);
  const [usagePerEntity, setUsagePerEntity] = useState([]);

  const toggleVisibility = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  useEffect(() => {
    if (searchInput === '' || searchInput === null || searchInput === undefined) {
      /**
       * * Aggregating usages per entity per label */
      const usagesByLabel = usages.reduce((acc, usage) => {
        if (usage.entities.type === attributeName) { // Filter usages by entity type first
          const { label } = usage.entities; // Assuming `label` is the property you want to group by
          if (!acc[label]) {
            acc[label] = [];
          }
          acc[label].push(usage);
        }
        return acc;
      }, {});
      setUsagePerEntity(Object.values(usagesByLabel));
      /**
       * * ********** */
    } else if (searchInput && searchInput.type === attributeName) {
      const filteredUsages = usages?.filter((usg) => usg.entities?.label === searchInput.label);
      setUsagePerEntity(filteredUsages);
    }
  }, [usages, entityName, searchInput]);

  return (
    <Box>
      <Box sx={containerBoxStyle}>
        <Typography variant="h5" component="div" sx={{...typographyStyle, color: getColorByEntity(entityName) }}>{entityName}</Typography>

        {isToggled ? (
          <KeyboardArrowUpIcon onClick={toggleVisibility} />
        ) : (
          <KeyboardArrowDownIcon onClick={toggleVisibility} />
        )}
      </Box>
      <Divider variant="middle" sx={{ backgroundColor: '#eee', marginY: 2.5, borderBottomWidth: 1.75 }} />
      {isToggled && (
      <Box sx={{ m: 5 }}>
        {(searchInput === undefined || searchInput === null || searchInput === '') && usagePerEntity?.length > 0 && usagePerEntity?.map((usagesPerEntityLabel, index) => (
          <Usages key={index} usagesPerEntityLabel={usagesPerEntityLabel} />
        ))}
        {(searchInput !== undefined && searchInput !== null && searchInput !== '') && (searchInput.type === attributeName) && (usagePerEntity?.length > 0)
         && (searchInput !== undefined || searchInput === null) && (
         <Usages usagesPerEntityLabel={usagePerEntity} />
        )}
      </Box>
      )}
    </Box>
  );
}

export default Entry;
