/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Cir } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Entry from '../components/Entry';
import Navbar from '../components/Navbar';
import SearchBar from '../components/SearchBar';
import { selectSearchInput, getAllEntities, resetSearchStore, resetSearchInput } from '../redux/Slices/searchBarSlice';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import { selectOrgaId, selectUserEmail } from '../redux/Slices/userSlice';
import { getAllUsages, resetError, resetLoading, selectAllUsages } from '../redux/Slices/usageSlice';
import { useSocket } from '../utils/socketContext';
import Legend from '../components/Legend';
import { COLORS } from '../constants';
import { selectError, selectLoading } from '../redux/Slices/usageSlice';

const entities = ['Classes', 'Individuals', 'Relationships'];
const entitiesInDb = ['Class', 'Individual', 'Object Property'];

export const legendItems = [
  { label: 'Class', color: COLORS.greenLightConcerto },
  { label: 'Individuals', color: COLORS.lightBlue },
  { label: 'Relationships', color: COLORS.orangeConcerto },
  { label: 'None', color: COLORS.lightGrayChip },

];

function Axioms() {
  const dispatch = useAppDispatch();
  const usages = useAppSelector(selectAllUsages);
  const socket = useSocket();
  const orgaId = useAppSelector(selectOrgaId);
  const userEmail = useAppSelector(selectUserEmail);
  const searchInput = useAppSelector(selectSearchInput);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const error = useAppSelector(selectError);
  const loading = useAppSelector(selectLoading);


  useEffect(() => {
    // dispatch(resetError());
    // dispatch(resetLoading());
    dispatch(getAllUsages({ orgaId, userEmail }));
    dispatch(getAllEntities());
  }, [orgaId, dispatch, searchInput, updateTrigger, userEmail]);

  useEffect(() => {
    dispatch(resetSearchInput());
    dispatch(resetSearchStore());
    if (!socket || socket === null) return;
    if (socket && !socket.connected) {
      socket.connect();
    }
    socket.emit('onConnection', { orgaId, userEmail });

    socket?.on('UsageValidated', (data) => {
      setUpdateTrigger((prev) => prev + 1);
    });

    return () => {
      dispatch(resetError());
      dispatch(resetLoading());
      
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  return (
    <>
      <Navbar />
      {/* {error !== null && error !== undefined && error !== ''  ? (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', m:3 }}>
          <Typography variant="h6" color="error" sx={{m: 3}}>
            Oups, a problem occured while fetching the data. Please try again later.
          </Typography>
        </Box>
      ) : loading ? (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', m:3 }}>
          <Typography variant="h6" color="error" sx={{m: 3}}>
            Data is loading, please wait...
          </Typography>
          <CircularProgress />
        </Box>
      ) :  */}
        <>
        <Box sx={{display: 'flex', flexDirection: 'row-reverse', width: '100%'}}>
          <Legend items={legendItems}/>
        </Box>
        <Container sx={{ p: 1 }}>
          <SearchBar />
          <Typography sx={{ fontSize: '0.8rem', ml: '83%' }}>
            { `Total usages to validate:
            ${usages.length}`}
          </Typography>
          {entities.map((entity, index) => (
            <Entry
              key={entities.indexOf(entity)}
              entityName={entity}
              attributeName={entitiesInDb[index]}
            />
          ))}
        </Container>
      </>
    </>);
}

export default Axioms;

