/* eslint-disable no-console */
function sortAndGroupOptions(options) {
  const groupOrder = { Class: 1, Individual: 2, 'Object Property': 3 };
  /** As we're changing state in the Redux store, immutable data => spread operator */
  const optionsCopy = [...options];
  optionsCopy.sort((a, b) => groupOrder[a.type] - groupOrder[b.type]
  || a.label.localeCompare(b.label));

  return optionsCopy;
}

export default sortAndGroupOptions;
