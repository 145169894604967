/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@mui/material';
import Navbar from './Navbar';
import pendingImage from '../assets/images/pending.png';

function UnderConstruction({ name }) {
  return (
    <>
      <Navbar />
      <Box sx={{
        display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center',
      }}
      >
        <h1>
          Welcome to the
          { ` ${name} `}
          page!
        </h1>
        <img
          src={pendingImage}
          alt="Under Construction"
          style={{
            height: 'auto', width: 'auto', maxHeight: '70vh', maxWidth: '37vw',
          }}
        />
      </Box>
    </>
  );
}

export default UnderConstruction;
