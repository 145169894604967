/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import sortAndGroupOptions from '../utils/sortingEntities';
import { selectOrgaId } from '../redux/Slices/userSlice';
import {
  getEntities, selectSearchList, setSearchInput,
} from '../redux/Slices/searchBarSlice';

function SearchBar() {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const orgaId = useAppSelector(selectOrgaId);

  useEffect(() => {
    if (orgaId) dispatch(getEntities(orgaId));
  }, [orgaId]);

  const handleChange = (event, value) => {
    dispatch(setSearchInput(value));
  };
  const options = useAppSelector(selectSearchList);
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      padding={8}
    >
      <Grid item>
        <Autocomplete
          id="entity-search"
          options={sortAndGroupOptions(options)}
          groupBy={(opt) => opt.type}
          getOptionLabel={(option) => option?.label}
          sx={{ width: '50vw', display: 'flex', alignItems: 'center' }}
          renderInput={(params) => <TextField {...params} label="Search by entity" variant="outlined" />}
          onChange={handleChange}
          // onInputChange={(event, newInputValue) => {
          //   setInputValue(newInputValue);
          // }}
        />
      </Grid>
    </Grid>
  );
}

export default SearchBar;
