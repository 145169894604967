/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Security from './Account/Security';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { selectUserEmail, validatedUsages } from '../../redux/Slices/userSlice';
// import Dashboard from '../../pages/Dashboard';
import DashboardBis from '../../pages/DashboardBis';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
          p: 3, width: '70vw', alignItems: 'center',
        }}
        >
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function SideMenu({ data }) {
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectUserEmail);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(validatedUsages(email));
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1, bgcolor: 'background.paper', display: 'flex', mt: 6,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1, borderColor: 'divider',
        }}
      >
        <Tab label={data.labelOne} {...a11yProps(0)} />
        <Tab
          label={data.labelTwo}
          {...a11yProps(1)}
        />
        <Tab label={data.labelThree} {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        My informations
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItem: 'baseline',
        }}
      >
        <Security />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DashboardBis />
      </TabPanel>

    </Box>
  );
}

export default SideMenu;
