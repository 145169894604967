/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { COLORS } from '../constants/index';
import Confirmation from './Confirmation';
import { useAppDispatch, useAppSelector } from '../redux/hooks/hooks';
import { valUsage } from '../redux/Slices/usageSlice';
import MemoizedTextField from '../utils/MemoizedTextField';
import { selectUserEmail, selectOrgaId } from '../redux/Slices/userSlice';
import { useSocket } from '../utils/socketContext';

/**
 * Utils :Component for details in the vote form
 */
function DetailDisplay({ label, content }) {
  return (
    <Box sx={{ mt: 2, textAlign: 'left', width: '100%' }}>
      <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'bold' }}>
        {label}
        :
      </Typography>
      <Typography variant="subtitle2" component="span" sx={{ ml: 1 }}>
        {content}
      </Typography>
    </Box>
  );
}

/**
 * Component for the vote form
 */

function VoteForm({ setOpenDialog, usageDetails }) {
  const socket = useSocket();
  const dispatch = useAppDispatch();
  // const userEmail = useAppSelector(selectUserEmail);
  const orgaId = useAppSelector(selectOrgaId);
  const email = useAppSelector(selectUserEmail);
  const [vote, setVote] = useState(null);
  const [error, setError] = useState(false);
  const [comment, setComment] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleVote = (selectedVote) => {
    setVote(selectedVote);
    const valInfo = {
      usageId: usageDetails.usageID,
      vote: selectedVote,
      comment: '',
      email,
    };
    if (selectedVote === 'OK') {
      dispatch(valUsage(valInfo));
      setShowSnackbar(true);
      socket?.emit('UsageValidated', { valInfo, orgaId });
      setTimeout(() => {
        setOpenDialog(false);
      }, 500);
    } else {
      setError(false);
      setComment('');
    }
  };

  const handleCommentChange = (event) => {
    const newComment = event.target.value;
    if (newComment !== comment) { // Only update if different
      setComment(newComment);
    }
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleSubmit = () => {
    if (vote === 'NOK' && !comment.trim()) {
      setError(true);
      return;
    }
    const valInfo = {
      usageId: usageDetails.usageID,
      vote: 'NOK',
      comment,
      email,
    };
    socket?.emit('UsageValidated', { valInfo, orgaId });
    dispatch(valUsage(valInfo));
    setShowSnackbar(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 500);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
    }}
    >
      <Typography variant="h5" component="div">Usage validation</Typography>
      <Box mt={5}>
        <DetailDisplay label="For entity type" content={usageDetails.entityType} />
        <Divider />
        <DetailDisplay label="For entity label" content={usageDetails.entityLabel} />
        <Divider />
        <Box sx={{
          ml: 1, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start',
        }}
        >
          <DetailDisplay label="Subject" content={usageDetails.subject} />
          <DetailDisplay label="Predicate" content={usageDetails.predicate} />
          <DetailDisplay label="Object" content={usageDetails.object} />
        </Box>
      </Box>
      <Box mt={5}>
        <Button variant="contained" color="success" onClick={() => handleVote('OK')} sx={{ backgroundColor: COLORS.greenConcerto, fontWeight: 'bold' }}>
          OK
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleVote('NOK')}
          sx={{
            marginLeft: 2, backgroundColor: COLORS.greenConcerto, fontWeight: 'bold', opacity: 0.65,
          }}
        >
          NOK
        </Button>
      </Box>
      {vote === 'NOK' && (
        <Box mt={2}>
          <MemoizedTextField
            label="Comment"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={comment}
            onChange={handleCommentChange}
            error={error}
            helperText={error ? 'Comment is required' : ''}
          />
          <Button variant="contained" onClick={handleSubmit} disabled={!comment.trim()} sx={{ marginTop: 2, backgroundColor: COLORS.blueConcerto }}>
            Submit
          </Button>
        </Box>
      )}
      <Confirmation
        open={showSnackbar}
        handleClose={handleCloseSnackbar}
        message="Your vote has been submitted successfully!"
        severity="success"
      />
    </Box>
  );
}

export default VoteForm;
