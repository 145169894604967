const COLORS = {
  primary: '#312651',
  secondary: '#444262',
  tertiary: '#FF7754',
  black: '#000000',

  gray: '#83829A',
  gray2: '#C1C0C8',
  lightGray: '#DCDCDC',

  white: '#F3F4F8',
  lightWhite: '#FAFAFC',

  greenConcerto: '#78b628',
  greenDarkConcerto: '#5b7041',
  greenLightConcerto: '#cceaa4',
  orangeConcerto: '#f5a623',
  blueConcerto: '#006eab',
  lightBlueConcerto: '#0177b7',
  lightGrayChip: '#C1C0C8',
  lightBlue: '#609aba',
  darkBlueConcerto: '#216184', //
};

const FONT = {
  regular: 'English with Indian rupee Regular',
};

const SHADOWS = {
  small: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 2,
  },
  medium: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 5.84,
    elevation: 5,
  },
};

export {
  COLORS, FONT, SHADOWS,
};
